// extracted by mini-css-extract-plugin
export var cultureWrapper = "culture-module--cultureWrapper--kYa86";
export var verticalContainer = "culture-module--verticalContainer--iQ18k";
export var verticalContainerInner = "culture-module--verticalContainerInner--FPfuB";
export var cultureContainer = "culture-module--cultureContainer--ech-7";
export var cultureTitle = "culture-module--cultureTitle--F7szL";
export var cultureQuote = "culture-module--cultureQuote--qcdUj";
export var cardWrapper = "culture-module--cardWrapper---UuIm";
export var cardContainer = "culture-module--cardContainer--HYiVg";
export var cultureImg = "culture-module--cultureImg--6DACa";
export var listContainer = "culture-module--listContainer--mTWbf";
export var breadCrumbsSection = "culture-module--breadCrumbsSection--s+W8u";