// extracted by mini-css-extract-plugin
export var socialIconContainer = "socialLink-module--socialIconContainer--MkgU+";
export var socialLinkWrapperDark = "socialLink-module--socialLinkWrapperDark--s3Cl-";
export var socialLinkWrapperLight = "socialLink-module--socialLinkWrapperLight--ckruc";
export var linkWrapper = "socialLink-module--linkWrapper--GIuxt";
export var darkSocialHeader = "socialLink-module--darkSocialHeader--PtUzO";
export var lightSocialHeader = "socialLink-module--lightSocialHeader--HJjfc";
export var darkSocialLinks = "socialLink-module--darkSocialLinks--YZTkF";
export var lightSocialLinks = "socialLink-module--lightSocialLinks--PPH+b";
export var socialLinksContainer = "socialLink-module--socialLinksContainer--XPDdu";
export var linkContainer = "socialLink-module--linkContainer--mjb7I";
export var linkFlex = "socialLink-module--linkFlex--aXFqD";
export var socialIcon = "socialLink-module--socialIcon--L2Xtx";
export var hide = "socialLink-module--hide--wMEaR";