import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { composeClasses } from 'utils'

import * as SocialStyles from '../styles/socialLink.module.css'

const SocialLinks = ({
  hasWhiteBackground,
  socialLinks,
  containerStyles = [],
  iconStyles = []
}) => {
  return (
    <div
      className={composeClasses(
        SocialStyles.socialIconContainer,
        ...containerStyles
      )}
    >
      <div
        className={
          hasWhiteBackground === false
            ? SocialStyles.socialLinkWrapperDark
            : SocialStyles.socialLinkWrapperLight
        }
      >
        {socialLinks && (
          <div
            className={SocialStyles.linkWrapper}
          >
            <h4
              className={
                hasWhiteBackground === false
                  ? SocialStyles.lightSocialHeader
                  : SocialStyles.darkSocialHeader
              }
            >
              Keep up with Deimos
            </h4>
            <div className={SocialStyles.socialLinksContainer}>
              {socialLinks.map(
                ({ socialLogo, socialUrl, socialTitle }, index) => {
                  return (
                    <div
                      className={SocialStyles.linkContainer}
                      key={index.toString()}
                    >
                      <div className={SocialStyles.linkFlex}>
                        <a
                          className={composeClasses(
                            SocialStyles.socialIcon,
                            ...iconStyles
                          )}
                          href={socialUrl}
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          {parse(socialLogo)}
                          <span className={SocialStyles.hide}>
                            {index.toString()}
                          </span>
                        </a>
                        <span
                          className={
                            hasWhiteBackground === false
                              ? SocialStyles.lightSocialLinks
                              : SocialStyles.darkSocialLinks
                          }
                        >
                          {socialTitle}
                        </span>
                      </div>
                    </div>
                  )
                }
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

SocialLinks.propTypes = {
  containerStyles: PropTypes.array,
  hasWhiteBackground: PropTypes.bool,
  iconStyles: PropTypes.array,
  socialLinks: PropTypes.array.isRequired
}

export default SocialLinks
