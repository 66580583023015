import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from 'components/seo'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { composeClasses, jsonId } from 'utils'
import parse from 'html-react-parser'
import SocialLinks from 'components/socialLinks'
import HelmetExport from 'react-helmet'
import {
  cardContainer,
  cultureImg,
  cultureTitle,
  cultureWrapper,
  cultureQuote,
  breadCrumbsSection,
  cultureContainer,
  cardWrapper,
  listContainer,
  verticalContainer,
  verticalContainerInner
} from '../styles/culture.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "Culture And Values" }) {
        edges {
          node {
            cultureAndValuesFieldGroup {
              cultureHeroHeading
              cultureService {
                cultureAndValuesTitle
                cultureAndValuesQuote
                cultureAndValuesDescription
                cultureAndValuesImage {
                  sourceUrl
                }
              }
            }
            slug
            title
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              socialLinks {
                socialTitle
                socialLogo
                socialUrl
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const CultureAndValues = ({ data, pageContext }) => {
  const cultureData = data.wordPress.pages.edges[0].node
  const cultureValues = cultureData.cultureAndValuesFieldGroup.cultureService
  const footerData = data.wordPress.footers.edges[0].node.footersFieldGroup
  const cultureHeading =
    cultureData.cultureAndValuesFieldGroup.cultureHeroHeading
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <div className={cultureWrapper}>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/careers`,
            'name': 'Careers',
            'position': 2
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 3
          }]
        })}
      </HelmetExport>
      <SEO
        description='This page explains Deimos’ Culture & Values and how they are used to help our members and their careers.'
        title='Culture & Values'
      />
      <div
        className={composeClasses(
          verticalContainer,
          breadCrumbsSection
        )}
      >
        <div className={verticalContainerInner}>
          <Breadcrumb
            crumbLabel='Culture & Values'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
        </div>
      </div>
      {cultureValues.length > 0 && (
        <div className={cultureContainer}>
          <h1>{cultureHeading}</h1>
          {cultureValues.map((value, key) => {
            return (
              <div
                className={cardWrapper}
                key={key.toString()}>
                <div className={cardContainer}>
                  <div className={cultureTitle}>
                    <h3>{value.cultureAndValuesTitle}</h3>
                    <div className={cultureQuote}>{value.cultureAndValuesQuote}</div>
                    <div className={listContainer}>{parse(value.cultureAndValuesDescription)}</div>
                  </div>
                  <img
                    alt='initiativeImage'
                    className={cultureImg}
                    height={160}
                    src={value.cultureAndValuesImage.sourceUrl}
                    width={336}
                  />
                </div>
              </div>
            )
          })}
        </div>
      )}
      <SocialLinks
        hasWhiteBackground
        socialLinks={footerData.socialLinks} />
    </div>
  )
}

CultureAndValues.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default CultureAndValues
